import type { ObserverOptions } from 'ecce';
import { observe, unobserve } from 'ecce';
import { useLayoutEffect, useReducer } from 'react';
import { isPropertyKey } from 'ecce/dist/util';


const useRerender = (): VoidFunction => useReducer(() => ({}), {})[1];

export type UseSubjectHook = {
	<T extends object, K extends keyof T>(subject: T, key: K, options?: Partial<ObserverOptions>): void;
	<T extends object>(subject: T, options?: Partial<ObserverOptions>): void;
};

export const useSubject: UseSubjectHook = <T extends object, K extends keyof T>(subject: T, keyOrOptions: K | Partial<ObserverOptions> | undefined, options?: Partial<ObserverOptions>): void => {
	const rerender = useRerender();

	useLayoutEffect(() => {
		// Observe a specific key.
		if(isPropertyKey(keyOrOptions)) {
			observe(subject, keyOrOptions, rerender, options);

			return () => {
				unobserve(subject, keyOrOptions, rerender);
			};
		}

		// Observer entire subject.
		observe(subject, rerender);

		return () => {
			unobserve(subject, rerender);
		};
	}, [subject, keyOrOptions, options]);
};