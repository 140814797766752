import type { Static } from '@sinclair/typebox';
import { Type } from '@sinclair/typebox';
import { brokerItemIdSchema } from './chargebee';


export const Form = Symbol('purser-field-def');
export type FieldDef = {
	emptyLabel?: string;
	phone?: boolean;
};


declare module '@sinclair/typebox' {
	interface StringOptions<TFormat extends string> extends SchemaOptions { // eslint-disable-line @typescript-eslint/no-unused-vars
		[Form]?: FieldDef;
	}
	interface SchemaOptions {
		[Form]?: FieldDef;
	}
}

export const associationSchema = Type.Union([
	Type.Literal('AYCA', { title: 'AYCA - Atlantic Yacht Charter Associates' }),
	Type.Literal('CYBA', { title: 'CYBA - Charter Yacht Brokers Association' }),
	Type.Literal('MYBA', { title: 'MYBA - Mediterranean Yacht Brokers Association' }),
	Type.Literal('IYBA', { title: 'IYBA - International Yacht Brokers Association' }),
	Type.Literal('HYBA', { title: 'HYBA - Hellenic Yacht Brokers Association' }),
	Type.Literal('GYA',  { title: 'GYA - Greek Yachting Association' }),
	Type.Literal('CPYB', { title: 'CPYB - Certified Professional Yacht Brokers' }),
], { title: 'Yachting Association', [Form]: { emptyLabel: 'Currently unaffiliated' } });
export type Association = Static<typeof associationSchema>;

export const personalInfoSchema = Type.Object({
	firstName: Type.String({ title: 'First Name', maxLength: 32 }),
	lastName: Type.String({ title: 'Last Name', maxLength: 32 }),
	phone: Type.String({ title: 'Phone Number', maxLength: 32, [Form]: { phone: true } }),
});

export const companyInfoSchema = Type.Object({
	companyName: Type.String({ title: 'Company Name', maxLength: 80 }),
	website: Type.String({ title: 'Website', maxLength: 128 }),
	association: Type.Optional(associationSchema),
});

export const addressInfoSchema = Type.Object({
	address: Type.String({ title: 'Address', maxLength: 80 }),
	city: Type.String({ title: 'City', maxLength: 80 }),
	state: Type.String({ title: 'State / County', maxLength: 80 }),
	postcode: Type.String({ title: 'ZIP / Postcode', maxLength: 80 }),
	country: Type.String({ title: 'Country', minLength: 2, maxLength: 2 }),
	latitude: Type.Optional(Type.Number()),
	longitude: Type.Optional(Type.Number()),

	officePhone: Type.Optional(Type.String({ title: 'Office Phone', maxLength: 80, [Form]: { phone: true } })),

	billingAddress: Type.Boolean({ title: 'Same as Billing Address' }),
});

export const signupInfoSchema = Type.Intersect([
	personalInfoSchema,
	companyInfoSchema,
	addressInfoSchema,
]);
export type SignupInfo = Static<typeof signupInfoSchema>;

export const brokerSubscriptionInfoNone = Type.Object({
	enabled: Type.Literal(false),
});
export const brokerSubscriptionInfoSome = Type.Object({
	enabled: Type.Literal(true),
	item: brokerItemIdSchema,
});
export const brokerSubscriptionInfo = Type.Union([
	brokerSubscriptionInfoNone,
	brokerSubscriptionInfoSome,
]);
export namespace BrokerSubscriptionInfo {
	export type None = Static<typeof brokerSubscriptionInfoNone>;
	export type Some = Static<typeof brokerSubscriptionInfoSome>;
}
export type BrokerSubscriptionInfo = Static<typeof brokerSubscriptionInfo>;


export const yachtListingSubscriptionInfoNone = Type.Object({
	enabled: Type.Literal(false),
});
export const yachtListingSubscriptionInfoSome = Type.Object({
	enabled: Type.Literal(true),
	quantity: Type.Integer(),
});
export const yachtListingSubscriptionInfo = Type.Union([
	yachtListingSubscriptionInfoNone,
	yachtListingSubscriptionInfoSome,
]);

export namespace YachtListingSubscriptionInfo {
	export type None = Static<typeof yachtListingSubscriptionInfoNone>;
	export type Some = Static<typeof yachtListingSubscriptionInfoSome>;
}
export type YachtListingSubscriptionInfo = Static<typeof yachtListingSubscriptionInfo>;

export const subscriptionsInfoSchema = Type.Object({
	broker: brokerSubscriptionInfo,
	yachtListing: yachtListingSubscriptionInfo,
});
