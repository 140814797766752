import type { Static } from '@sinclair/typebox';
import { Type } from '@sinclair/typebox';


export const itemFamilyIdSchema = Type.Union([
	Type.Literal('Charter-Broker-Subscriptions'),
	Type.Literal('Central-Agent-Listings'),
], { title: 'ItemFamilyId' });
export type ItemFamilyId = Static<typeof itemFamilyIdSchema>;

export const itemFamilySchema = Type.Object({
	id: itemFamilyIdSchema,
	name: Type.String(),
}, { title: 'ItemFamily' });
export type ItemFamily = Static<typeof itemFamilySchema>;

export const brokerItemIdSchema = Type.Union([
	// Old IDs
	Type.Literal('API-Charter-Broker-Subscription'),
	Type.Literal('Professional-Charter-Broker-Subscription'),
	Type.Literal('Boutique-Charter-Broker-Subscription'),
	Type.Literal('Individual-Charter-Broker-Subscription'),

	// New IDs
	Type.Literal('api-subscriptions'),
	Type.Literal('boutique-subscriptions'),
	Type.Literal('corporate-subscriptions'),
	Type.Literal('single-subscriptions'),
]);
export const yachtListingItemIdSchema = Type.Literal('Yacht-Listing');

export const isOldBrokerItemId = (x: string): boolean => {
	switch(x) {
		case 'API-Charter-Broker-Subscription':
		case 'Professional-Charter-Broker-Subscription':
		case 'Boutique-Charter-Broker-Subscription':
		case 'Individual-Charter-Broker-Subscription':
			return true;
		default:
			return false;
	}
};

export const itemIdSchema = Type.Union([
	brokerItemIdSchema,
	yachtListingItemIdSchema,
], { title: 'ItemId' });

export namespace ItemId {
	export type Broker = Static<typeof brokerItemIdSchema>;
	export type YachtListing = Static<typeof yachtListingItemIdSchema>;
}
export type ItemId = Static<typeof itemIdSchema>;

export const itemSchema = Type.Object({
	id: itemIdSchema,
	external_name: Type.String(),
	item_family_id: itemFamilyIdSchema,
}, { title: 'Item' });
export type Item = Static<typeof itemSchema>;

export const periodUnitSchema = Type.Union([
	Type.Literal('year'),
	Type.Literal('month'),
], { title: 'PeriodUnit' });
export type PeriodUnit = Static<typeof periodUnitSchema>;

export const PERIOD_UNITS: Readonly<PeriodUnit[]> = Object.freeze([ 'month', 'year' ]);

export const pricingModelSchema = Type.Union([
	Type.Literal('flat_fee'),
	Type.Literal('per_unit'),
], { title: 'PricingModel' });
export type PricingModel = Static<typeof pricingModelSchema>;

export const itemPriceSchema = Type.Object({
	id: Type.String(),
	period: Type.Number(),
	item_family_id: itemFamilyIdSchema,
	item_id: itemIdSchema,
	period_unit: periodUnitSchema,
	pricing_model: pricingModelSchema,
	price: Type.Integer(),
	description: Type.Optional(Type.String()),
}, { title: 'Item Price' });
export type ItemPrice = Static<typeof itemPriceSchema>;

export const hostedPageStateSchema = Type.Union([
	Type.Literal('created'),
	Type.Literal('requested'),
	Type.Literal('succeeded'),
	Type.Literal('cancelled'),
	Type.Literal('acknowledged'),
]);
export type HostedPageState = Static<typeof hostedPageStateSchema>;