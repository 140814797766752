import type { ObserverOptions } from './observe';
import { Notifier } from './notifier';


export class ObserverSet implements Iterable<VoidFunction> {
	private static EMPTY_ITERATOR: Iterator<VoidFunction> = {
		next: () => ({ done: true, value: undefined }),
	};

	private static NOTIFIER = new Notifier();

	private immediateObservers: Set<VoidFunction> | null = null;
	private latentObservers: Set<VoidFunction> | null = null;

	constructor(public readonly name: string) {}

	[Symbol.iterator](): Iterator<VoidFunction, any, undefined> {
		return this.latentObservers?.[Symbol.iterator]() ?? ObserverSet.EMPTY_ITERATOR;
	}

	addObserver(observer: VoidFunction, options: Partial<ObserverOptions> | undefined) {
		if(options?.immediate) {
			(this.immediateObservers ??= new Set()).add(observer);
			return;
		}

		(this.latentObservers ??= new Set()).add(observer);
	}

	removeObserver(observer: VoidFunction) {
		this.latentObservers?.delete(observer);
	}

	notify() {
		this.immediateObservers?.forEach(observer => observer());
		ObserverSet.NOTIFIER.enqueue(this);
	}
}