import type { ObservablePropertyOptions } from './observable-property-options';
import type { ObserverOptions } from './observe';
import type { SubjectDef } from './subject-def';
import { ObserverSet } from './observer-set';
import { EcceError } from './error';


/**
 * Defines an observable property.
 */
export class PropertyDef {
	/**
	 * Observers registered for this property, mapped by instance.
	 */
	private readonly observers = new WeakMap<object, ObserverSet>();

	public readonly name: string;

	readonly applyToInstance: (instance: any, source?: any) => void;

	/**
	 * @param subjectDef the owner of this property
	 * @param key key of the property this defines
	 * @param originalDescriptor original property descriptor for the property, if
	 * 	one exists.
	 */
	constructor(private readonly subjectDef: SubjectDef, private readonly key: PropertyKey, private readonly originalDescriptor: PropertyDescriptor | undefined, _options: Partial<ObservablePropertyOptions> | undefined) {
		this.name = `${subjectDef.name}.${String(key)}`;

		const descriptor = this.originalDescriptor;
		if(!descriptor) {
			/*
				Without a descriptor, we have a simple value property.
				https://www.typescriptlang.org/docs/handbook/decorators.html#property-decorators.

				Overwrite the instance's original property with a new property which
				notifies the instance's property observers on set.
			*/
			this.applyToInstance = (instance, source) => {
				this.createObserversFor(instance);

				let value = (source ?? instance)[this.key];

				Object.defineProperty(instance, this.key, {
					enumerable: true,
					get: () => value,
					set: nextValue => {
						value = nextValue;
						this.notify(instance);
					},
				});
			};
		} else {
			/*
				With a descriptor, we have an accessor property.
				https://www.typescriptlang.org/docs/handbook/decorators.html#accessor-decorators.
			*/
			this.applyToInstance = (instance) => {
				if(!originalDescriptor?.set) {
					throw new EcceError(`Cannot use @observable() on a getter without a setter: "${this.subjectDef.name}.${String(this.key)}"`);
				}

				this.createObserversFor(instance);

				// Setter must be bound to instance to allow mutation of #private members.
				const originalSetter = originalDescriptor.set.bind(instance);

				Object.defineProperty(instance, key, {
					...originalDescriptor,
					set: nextValue => {
						originalSetter(nextValue);
						this.notify(instance);
					},
				});
			};
		}
	}

	private createObserversFor(instance: any) {
		this.observers.set(instance, new ObserverSet(this.name));
	}

	private getObserversFor(instance: any): ObserverSet {
		const observers = this.observers.get(instance);
		/* istanbul ignore if */
		if(!observers) {
			/*
				By the time we ever call this method, `applyToInstance` would have been
				invoked on instance, so this should never happen...
			*/
			throw new EcceError('ObservableProperty had no Observers for an instance; probably a bug in ecce...');
		}

		return observers;
	}

	/**
	 * Add an observer for this property on the passed instance.
	 */
	addObserver(instance: object, observer: VoidFunction, options?: Partial<ObserverOptions>) {
		this.getObserversFor(instance).addObserver(observer, options);
	}

	/**
	 * Remove an observer for this property on the passed instance.
	 */
	removeObserver(instance: object, observer: VoidFunction) {
		this.getObserversFor(instance).removeObserver(observer);
	}

	/**
	 * Notify this property's observers for the passed instance.
	 */
	private notify(instance: any) {
		this.getObserversFor(instance).notify();
		this.subjectDef.notify(instance);
	}
}