import type { ObserverSet } from './observer-set';
import queueMicrotask from 'queue-microtask';
import { EcceError } from './error';


export class Notifier {
	private readonly notificationQueue = new Set<ObserverSet>;
	private readonly notificationTrace = new Set<ObserverSet>;

	private willNotifyThisTick = false;

	public enqueue(set: ObserverSet): void {
		if(this.notificationQueue.has(set)) {
			return;
		}

		if(this.notificationTrace.has(set)) {
			const trace = [ ...this.notificationTrace, set ]
				.map(observer => `"${observer.name}"`)
				.join(' -> ');

			throw new EcceError('Recursive observers: ' + trace);
		}

		this.notificationQueue.add(set);

		if(!this.willNotifyThisTick) {
			this.willNotifyThisTick = true;

			queueMicrotask(() => {
				try {
					this.notify();
				} finally {
					this.willNotifyThisTick = false;
					this.notificationQueue.clear();
					this.notificationTrace.clear();
				}
			});
		}
	}

	private notify() {
		const toNotify = [ ...this.notificationQueue ];
		this.notificationQueue.clear();

		for(const set of toNotify) {
			this.notificationTrace.add(set);

			for(const observer of set) {
				observer();

				/*
					If notifying the observer resulted in more Observers being enqueued
					for notification, notify them immediately. This allows us to catch
					recursive observer chains.
				*/
				if(this.notificationQueue.size) {
					this.notify();
				}
			}

			this.notificationTrace.delete(set);
		}
	}
}