customBrandingColors:
  title: Custom Branding Colours
  text: |
    After installing the plugin on your WordPress website you will be invited to
    select some basic branding, text and background colours to be used within
    the plugin.
    
    This colour selector allows each plugin user to completely
    customise the user interface to better match their website look and feel so
    it blends in seamlessly.
    
    Full control of branding colour, Heading text
    colour, plugin background colour and general text colour are all available.

metaContent:
  title: Meta Content
  text: |
    The meta page titles created within the plugin are one of the most important
    on-page ranking factors and should be treated with care. Your page title tag
    shows up in Search Engine Result Pages (SERPs).
    
    The meta description is a ~160 character snippet, a tag in HTML, that
    summarises a page’s content. Search engines show the meta description in
    search results mostly when the searched for phrase is contained in the
    description. Optimising the meta description is a very important aspect of
    on-page SEO.
    
    We have added full dynamic control of the meta titles and descriptions
    allowing the use of smart tags that change content depending on what content
    you are looking at.

yoast:
  title: Fully compatible with Yoast
  text: |
    The Charter Index WordPress plugin is fully ring-fenced from any Yoast SEO
    plugin work meaning that you can enjoy continued use of your optimisation
    plugin while not interfering with the Charter Index API content.
    
    This important feature was widely requested by our user base who had all
    been using Yoast to manage all their non dynamic website SEO content.

shortCodes:
  title: Shortcodes made simple
  text: |
    Shortcodes can be used through your Blog and Website pages in order to
    invoke what ever you want.
    
    Populate a blog with a hand picked short list of
    yachts by yacht ID which you are writing about or go as far as opening a
    results page with filters applied to show the yachts available in the right
    area as well as other filter fields which can be applied.