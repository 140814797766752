import { ObjectSubjectDef } from './subject-def';


export type MakeSubjectOptions = {
	name: string;
};

export type ObservableObjectValue = string | number | bigint | boolean | symbol;
export type ObservableObject = Record<string, ObservableObjectValue>;


export const makeSubject = <T extends ObservableObject>(object: T, options?: Partial<MakeSubjectOptions>): T => (
	ObjectSubjectDef.makeForInstance(object, options)
);