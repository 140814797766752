api:
  caption: |
    The API configuration above shows how the raw yacht data may be incorporated
    and manipulated in whatever way you wish, within your own website
  easeOfUse: |
    The API service is very easy to install for developers familiar with RESTful
    API's or even easier if you choose to use the WordPress plugin
  seo: |
    Very good, you increase your footprint by over 1600 pages that can all have
    their meta tags customised
  yachts: |
    All the charter yachts in the world without any exceptions regardless of
    listing listing status within our data services
  userInterface: |
    Completely within your control if done through a custom build and very
    flexible through the WordPress plugin
iframe:
  caption: |
    The IFrame configuration above shows the clear separation between the
    structure of the two systems and the way the IFrame page is loaded within
    the clients website
  easeOfUse: |
    The IFrame service is very easy to install and only requires a single line
    of code to punch a hole in the proposed search engine page.
  seo: |
    Not good as all the yacht content is viewed through an invisble window and
    the content is still all still via the charter index URL
  yachts: |
    Full listings only meaning that you have a reduced catalogue of yachts
    available for your clients to go through
  userInterface: |
    The window that the IFrame sits within can be customised but the actual
    functionality, user interface and colours are fixed