import type { Static } from '@sinclair/typebox';
import { Type } from '@sinclair/typebox';
import { hostedPageStateSchema, itemFamilyIdSchema, itemFamilySchema, itemPriceSchema, itemSchema, signupInfoSchema } from './domain';


export const createEmailVerificationRequestSchema = Type.Object({
	email: Type.String({ format: 'email', maxLength: 256 }),
}, { title: 'EmailVerificationRequest' });
export type CreateEmailVerificationRequest = Static<typeof createEmailVerificationRequestSchema>;

export const verifyEmailErrorSchema = Type.Union([
	Type.Literal('ADDRESS_IN_USE'),
	Type.Literal('DOMAIN_IN_USE'),
]);
export type VerifyEmailError = Static<typeof verifyEmailErrorSchema>;

export const createEmailVerificationResponseOkSchema = Type.Object({
	ok: Type.Literal(true),
	hash: Type.String(),
}, { title: 'CreateEmailVerificationResponse' });
export const createEmailVerificationResponseErrorSchema = Type.Object({
	ok: Type.Literal(false),
	error: verifyEmailErrorSchema,
});
export const createEmailVerificationResponseSchema = Type.Union([
	createEmailVerificationResponseOkSchema,
	createEmailVerificationResponseErrorSchema,
]);

export namespace CreateEmailVerificationResponse {
	export type Ok = Static<typeof createEmailVerificationResponseOkSchema>;
	export type Error = Static<typeof createEmailVerificationResponseErrorSchema>;
}
export type CreateEmailVerificationResponse = Static<typeof createEmailVerificationResponseSchema>;

export const checkEmailVerificationRequestSchema = Type.Object({
	code: Type.Integer(),
	hash: Type.String(),
}, { title: 'CheckEmailVerificationRequest' });
export type CheckEmailVerificationRequest = Static<typeof checkEmailVerificationRequestSchema>;

export const checkEmailVerificationResponseSchema = Type.Object({
	ok: Type.Boolean(),
}, { title: 'CheckEmailVerificationResponse' });
export type CheckEmailVerificationResponse = Static<typeof checkEmailVerificationResponseSchema>;

export const createSubscriptionRequestSchema = Type.Object({
	id: Type.Optional(Type.String()),
	email: Type.String({ format: 'email' }),
	signupInfo: signupInfoSchema,
	itemPriceId: Type.String(),
	quantity: Type.Optional(Type.Number()),
}, { title: 'CreateSubscriptionRequest' });
export type CreateSubscriptionRequest = Static<typeof createSubscriptionRequestSchema>;

export const createSubscriptionResponseOkSchema = Type.Object({
	id: Type.String(),
	kind: Type.Literal('ok'),
	hostedPageUrl: Type.String({ format: 'uri' }),
}, { title: 'CreateSubscriptionResponse.Ok' });
export const createSubscriptionResponseBadRequestSchema = Type.Object({
	id: Type.String(),
	kind: Type.Literal('bad-request'),
	field: Type.Literal('zip'),
});
export const createSubscriptionResponseSchema = Type.Union([
	createSubscriptionResponseOkSchema,
	createSubscriptionResponseBadRequestSchema,
]);
export namespace CreateSubscriptionResponse {
	export type Ok = Static<typeof createSubscriptionResponseOkSchema>;
	export type BadRequest = Static<typeof createSubscriptionResponseBadRequestSchema>;
}
export type CreateSubscriptionResponse = Static<typeof createSubscriptionResponseSchema>;
export type CreateSubscriptionInvalidField = CreateSubscriptionResponse.BadRequest['field'];

export const itemPriceInfoSchema = Type.Pick(itemPriceSchema, [ 'id', 'price', 'pricing_model', 'description' ], {
	title: 'ItemPriceInfo',
});
export type ItemPriceInfo = Static<typeof itemPriceInfoSchema>;

export const itemInfoSchema = Type.Intersect([
	itemSchema,
	Type.Object({
		prices: Type.Object({
			month: itemPriceInfoSchema,
			year: itemPriceInfoSchema,
		}),
	}),
], { title: 'ItemInfo' });
export type ItemInfo = Static<typeof itemInfoSchema>;

export const itemFamilyInfoSchema = Type.Intersect([
	itemFamilySchema,
	Type.Object({
		items: Type.Array(itemInfoSchema),
	}),
], { title: 'ItemFamilyInfo' });
export type ItemFamilyInfo = Static<typeof itemFamilyInfoSchema>;

export const contactRequestSchema = Type.Object({
	name: Type.String(),
	email: Type.String({ format: 'email' }),
	phone: Type.Optional(Type.String()),
	message: Type.String(),
	recaptchaToken: Type.String(),
}, { title: 'ContactRequest' });
export type ContactRequest = Static<typeof contactRequestSchema>;

export const hostedPageResultSchema = Type.Object({
	state: hostedPageStateSchema,
	itemFamilyId: itemFamilyIdSchema,
});
export type HostedPageResult = Static<typeof hostedPageResultSchema>;

export const verifyEmailResultOkSchema = Type.Object({
	ok: Type.Literal(true),
});

export const verifyEmailResultErrorSchema = Type.Object({
	ok: Type.Literal(false),
	error: verifyEmailErrorSchema,
});
export namespace VerifyEmailResult {
	export type Ok = Static<typeof verifyEmailResultOkSchema>;
	export type Error = Static<typeof verifyEmailResultErrorSchema>;
}
export const verifyEmailResultSchema = Type.Union([
	verifyEmailResultOkSchema,
	verifyEmailResultErrorSchema,
]);
export type VerifyEmailResult = Static<typeof verifyEmailResultSchema>;