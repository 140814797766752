import type { MakeSubjectOptions, ObservableObject } from './make-subject';
import type { ObservablePropertyOptions } from './observable-property-options';
import type { ObserverOptions } from './observe';
import { EcceError } from './error';
import { ObserverSet } from './observer-set';
import { PropertyDef } from './property-def';


const SUBJECT_DEF_KEY = Symbol('__ecce__');

const BASE_DEFAULT_PROPERTY_OPTIONS: Readonly<ObservablePropertyOptions> = Object.freeze({});
export abstract class SubjectDef {
	private readonly observers = new WeakMap<any, ObserverSet>();
	public readonly defaultPropertyOptions: Readonly<ObservablePropertyOptions> = BASE_DEFAULT_PROPERTY_OPTIONS;
	abstract readonly name: string;

	constructor(_defaultPropertyOptions: Partial<ObservablePropertyOptions> | undefined) {
		// TODO: Re-enabled once some options are present...
		// this.defaultPropertyOptions = defaultPropertyOptions
		// 	? Object.freeze({
		// 		...BASE_DEFAULT_PROPERTY_OPTIONS,
		// 		...defaultPropertyOptions,
		// 	})
		// 	: BASE_DEFAULT_PROPERTY_OPTIONS;
	}
	abstract getProperty(key: PropertyKey): PropertyDef | null;

	private getObserverFor(instance: any): ObserverSet {
		const observers = this.observers.get(instance);
		/* istanbul ignore if */
		if(!observers) {
			/*
				By the time we ever call this method, `applyToInstance` would have been
				invoked on instance, so this should never happen...
			*/
			throw new EcceError('SubjectDef had no Observers for an instance; probably a bug in ecce...');
		}

		return observers;
	}

	applyToInstance(instance: any) {
		this.observers.set(instance, new ObserverSet(this.name));
	}

	addObserver(instance: any, observer: VoidFunction, options?: Partial<ObserverOptions>) {
		this.getObserverFor(instance).addObserver(observer, options);
	}

	removeObservers(instance: any, observer: VoidFunction) {
		this.getObserverFor(instance).removeObserver(observer);
	}

	notify(instance: any) {
		this.getObserverFor(instance).notify();
	}

	static getFor(target: any): SubjectDef | null {
		return target[SUBJECT_DEF_KEY] ?? null;
	}
}

export class ClassSubjectDef extends SubjectDef {
	static getOrMakeFor(constructor: Function): ClassSubjectDef {
		return (constructor as any)[SUBJECT_DEF_KEY] ??= new ClassSubjectDef(constructor.name, undefined);
	}

	readonly source = 'decorator';

	private properties: Record<PropertyKey, PropertyDef> = {};
	private callbacks: Set<PropertyKey> | null = null;

	/**
	 * Whether this subject def was handled by a @subject() decorator.
	 */
	private wasDecorated = false;

	private constructor(readonly name: string, defaultPropertyOptions: Partial<ObservablePropertyOptions> | undefined) {
		super(defaultPropertyOptions);

		/*
			We want to emit a clear error message if @observable() or @callback are
			applied to members of a class without a @subject() decorator.

			As property / method decorators are invoked before class decorators, we
			have no way of knowing from those whether the class has the @subject()
			decorator or not.

			So, we set a timeout for next tick to check if the @subject() decorator was
			called, which will have set `wasDecorated` to true.

			This is a really ugly way of handling this, as it is basically impossible
			to ever catch this error. Ideally we would be able to throw this on
			construction of an invalid instance, but we will not have been able to
			proxy the class' constructor without the @subject() decorator...
		*/
		setTimeout(() => {
			if(!this.wasDecorated) {
				throw new EcceError(`Cannot use @observable() on a member of a class not decorated with @subject(): "${this.name}"`);
			}
		});
	}

	addProperty(key: PropertyKey, descriptor: PropertyDescriptor | undefined, options: Partial<ObservablePropertyOptions> | undefined) {
		this.properties[key] = new PropertyDef(this, key, descriptor, options);
	}

	getProperty(key: PropertyKey): PropertyDef | null {
		return this.properties[key] ?? null;
	}

	addCallback(key: PropertyKey): void {
		(this.callbacks ??= new Set()).add(key);
	}

	decorated() {
		this.wasDecorated = true;
	}

	applyToInstance(instance: any) {
		super.applyToInstance(instance);

		Object.defineProperty(instance, SUBJECT_DEF_KEY, {
			enumerable: false,
			configurable: false,
			writable: false,
			value: this,
		});

		for(const key in this.properties) {
			this.properties[key].applyToInstance(instance);
		}

		if(this.callbacks) {
			for(const key of this.callbacks) {
				const originalMethod = instance[key];

				if(typeof(originalMethod) !== 'function') {
					throw new EcceError(`Cannot apply @callback to non-function member: "${this.name}.${String(key)}"`);
				}

				instance[key] = originalMethod.bind(instance);
			}
		}

		return instance;
	}
}


export class ObjectSubjectDef extends SubjectDef {
	public readonly name: string;

	private readonly properties: Record<PropertyKey, PropertyDef> = {};

	static makeForInstance<T extends ObservableObject>(instance: T, options?: Partial<MakeSubjectOptions>): T {
		const def = new ObjectSubjectDef(options);

		const subject = {
			[SUBJECT_DEF_KEY]: def,
		};

		def.applyToInstance(subject);

		for(const key in instance) {
			const prop = def.properties[key] = new PropertyDef(def, key, undefined, {});
			prop.applyToInstance(subject, instance);
		}

		return subject as unknown as T;
	}

	private constructor(options: Partial<MakeSubjectOptions> = {}) {
		super(undefined);
		this.name = options?.name ?? 'Object';
	}

	getProperty(key: PropertyKey): PropertyDef | null {
		return this.properties[key] ?? null;
	}
}