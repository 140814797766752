import { ClassSubjectDef } from './subject-def';


export const subject = () => (
	(target: Function) => {
		const def = ClassSubjectDef.getOrMakeFor(target);
		def.decorated();

		const proxyCtor = new Proxy(target, {
			construct: (target, args) => def.applyToInstance(new (target as any)(...args)),
		}) as any;

		return proxyCtor;
	}
);