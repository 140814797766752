import type { ObservablePropertyOptions } from './observable-property-options';
import { EcceError } from './error';
import { ClassSubjectDef } from './subject-def';


export const observable = (options?: ObservablePropertyOptions) => (
	(target: object | Function, key: PropertyKey, descriptor?: PropertyDescriptor) => {
		/*
			If we are passed a function, it is the class constructor, and we are dealing
			with a static member.

			If passed an object, it is the class' prototype, and we are dealing with
			an instance member.

			https://www.typescriptlang.org/docs/handbook/decorators.html#property-decorators
		*/

		if(typeof(target) === 'function') {
			throw new EcceError(`Static @observable() members are not yet supported: "${target.name}.${String(key)}"`);
		}

		ClassSubjectDef
			.getOrMakeFor(target.constructor)
			.addProperty(key, descriptor, options);
	}
);