import type { PropertyDef } from './property-def';
import { EcceError } from './error';
import { ClassSubjectDef, SubjectDef } from './subject-def';
import { isPropertyKey } from './util';


export type ObserverOptions = {
	immediate: boolean;
};


const getSubjectDefForObservation = (verb: 'observe' | 'unobserve', subject: object): SubjectDef => {
	const def = SubjectDef.getFor(subject);
	if(!def) {
		const ctorName = Object.getPrototypeOf(subject)?.constructor?.name;
		// Bare object.
		if(! ctorName || ctorName === 'Object') {
			throw new EcceError(`Cannot ${verb} an object not created by subject().`);
		}

		// Class instance.
		throw new EcceError(`Cannot ${verb} an instance of a class without a @subject() decorator: "${ctorName}".`);
	}

	return def;
};

const getPropertyForObservation = (verb: 'observe' | 'unobserve', subject: object, key: PropertyKey): PropertyDef => {
	const def = getSubjectDefForObservation(verb, subject);

	const prop = def.getProperty(key);
	if(!prop) {
		if(def instanceof ClassSubjectDef) {
			throw new EcceError(`Cannot ${verb} a property without an @observable() decorator: "${def.name}.${String(key)}".`);
		}

		throw new EcceError(`Cannot ${verb} non-existant property: "${def.name}.${String(key)}"`);
	}

	return prop;
};

export type ObserveFn = {
	<T extends object, K extends keyof T>(subject: T, key: K, observer: VoidFunction, options?: Partial<ObserverOptions>): void
	<T extends object>(subject: T, observer: VoidFunction, options?: Partial<ObserverOptions>): void;
};

export const observe: ObserveFn = <T extends object, K extends keyof T>(subject: T, keyOrObserver: K | VoidFunction, observerOrOptions: VoidFunction | Partial<ObserverOptions> | undefined, options?: Partial<ObserverOptions>): void => {
	if(typeof(observerOrOptions) === 'function' && isPropertyKey(keyOrObserver)) {
		// Observing a single property
		getPropertyForObservation('observe', subject, keyOrObserver).addObserver(subject, observerOrOptions, options);
		return;
	}

	// Observing entire subject.
	getSubjectDefForObservation('observe', subject)
		.addObserver(subject, keyOrObserver as VoidFunction, options);
};

export type UnobserveFn = {
	<T extends object, K extends keyof T>(subject: T, key: K, observer: VoidFunction): void
	<T extends object>(subject: T, observer: VoidFunction): void;
};
export const unobserve: UnobserveFn = <T extends object, K extends keyof T>(subject: T, keyOrObserver: K | VoidFunction, observer?: VoidFunction): void => {
	if(observer && isPropertyKey(keyOrObserver)) {
		// Unobserving a single property.
		getPropertyForObservation('unobserve', subject, keyOrObserver).removeObserver(subject, observer);
		return;
	}

	getSubjectDefForObservation('unobserve', subject)
		.removeObservers(subject, keyOrObserver as VoidFunction);
};