export const isPropertyKey = (x: unknown): x is PropertyKey => {
	switch(typeof(x)) {
		case 'string':
		case 'number':
		case 'symbol':
			return true;
		default:
			return false;
	}
};

export type Primitive = (
	| string
	| number
	| bigint
	| boolean
	| undefined
	| symbol
);