- id: search-world
  title: Search the entire world
  text: |
    Find yachts from any location. Our search engine will accept any destination
    in the world you can think up and find you the nearest available charter
    yachts.

- id: responsive
  title: Responsive search engine
  text: |
    Use the Charter Index professional Charter Broker system on your hand held
    device while on the move or in front of your desktop. 

- id: currency
  title: Instant currency switching
  text: |
    Instantly switch out currencies while searching a page and see the converted
    and rounded amounts as well as the currency required.

- id: yacht-cards
  title: Advanced yacht cards
  text: |
    You will be able to see a lot of information at a single glance, from
    payment currency as well as location and rate verification information.

- id: instant-results
  title: Instant results
  text: ''

- id: date
  title: Advanced date filtering
  text: |
    Use our advanced charter date filtering which will allow exact dates or
    allows for flexible dates.

- id: keyword
  title: Keyword search
  text: |
    The ability to refine results by a specific amenity or toy on board will
    stop you wasting hours having to call around Central Agents.

- id: brochure
  title: Instant brochure sharing
  text: |
    Use our sharing tools to instantly send clients yacht brochures branded with
    your company information and enquiry engine.

- id: email
  title: Integrated emails
  text: |
    Send emails to central agents from within the system and track their status
    through the 'Email History' page.